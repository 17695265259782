import React, { useMemo } from "react"
import { graphql } from "gatsby"
import {
  Layout,
  useTranslate,
} from "@solublestudio/gatsby-theme-soluble-source"

import { replaceForParams } from "../utils/translations.js"

import Section from "../sections"

export default function TagPage({ data, pageContext }) {
  const translate = useTranslate()

  const pageData = useMemo(() => data?.page, [data?.page])

  return (
    <Layout
      navbarFixed={true}
      navbarTransparent={true}
      customSeo={{
        title: pageData?.seo
          ? pageData.seo.title
          : replaceForParams(translate["tag.seo.title"]?.text, [
              {
                key: "tag",
                value: pageData?.title,
              },
            ]),
        description: pageData?.seo
          ? pageData.seo.description
          : translate["tag.seo.description"]?.text,
      }}
      footerExtraParams={{
        alternateLanguages: pageContext.seo?.alternateLanguages,
      }}
      headerExtraParams={{
        alternateLanguages: pageContext.seo?.alternateLanguages,
        url: pageContext?.pageUrl,
      }}
    >
      {data.parentPage.sections.map((section, i) => (
        <Section
          key={i}
          order={i + 1}
          isLast={i + 1 === data.parentPage.sections.length}
          {...(section.__typename === "DatoCmsBlogSection"
            ? {
                ...section,
                currentSlug: data?.page?.slug,
                description: data?.page?.description,
                firstPageHref: data?.parentPage?.slug,
                posts: data.posts.edges.map(({ node }) => node),
                subtitle: "",
                title: data.page.title,
              }
            : section)}
        />
      ))}
    </Layout>
  )
}

export const query = graphql`
  query TagPage($id: String, $language: String) {
    page: datoCmsTag(id: { eq: $id }, locale: { eq: $language }) {
      title
      description
      slug
      seo {
        title
        description
      }
    }
    parentPage: getParentPage(locale: $language, type: "DatoCmsBlogSection") {
      ...DatoCmsBlogSectionParentPage
    }
    posts: allDatoCmsBlog(
      filter: {
        tags: { elemMatch: { id: { eq: $id } } }
        locale: { eq: $language }
      }
    ) {
      edges {
        node {
          ...PostItem
        }
      }
    }
  }
`
