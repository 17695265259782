export const replaceForParams = (value = "", replace = []) => {
  if (value) {
    if (replace) {
      let val = value
      replace.forEach((element) => {
        val = val.replace(`{${element.key}}`, element.value)
      })
      return val
    }
    return value
  }

  return value
}
